@font-face {
  font-family: "GilroyExtraBold";
  src: local("GilroyExtraBold"), url("./fonts/Gilroy/Gilroy-ExtraBold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "GilroyLight";
  src: local("GilroyLight"), url("./fonts/Gilroy/Gilroy-Light.otf") format("opentype");
  font-weight: light;
}


html {
  overscroll-behavior: none;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

html::-webkit-scrollbar {
  display: none;
}

body {
  overflow: hidden; /* Hide scrollbars */
  margin: 0;
  font-family: "Inter", "GilroyExtraBold", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --step0: #050505;
  --step1: #bad5ea;
  --step2: #fd8769;
  --step3: #ffdcb3;
  --step4: #ff615d;
}