
.App {
  text-align: center;
  /* background: linear-gradient(90deg, #12c2e9 0%, #c471ed 46%, #f64f59 100%); */
  /* background-color: #000000; */
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #232323;
 
}

.background {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: #eff0f3;
  z-index: -2;
}

.top-left {
  position: absolute;
  top: 125px;
  color: #f45d48;
}

.bottom-right {
  position: absolute;
  bottom: 100px;
}

.headline h1 {
  text-align: left;
  line-height: 0.8;
  font-weight: 800;
  font-size: calc(300px + 0.390625vw);
  letter-spacing: -20px;
  margin: 0;
  padding: 0;
  font-family: 'GilroyExtraBold';
}

.nav {
  z-index: 1;
  position: absolute;
  font-weight: 700;
  text-align: right;
  right: 0;
  top: 0;
  margin: 16px 25px 0px 0px;
  font-size: 1.5em;
}

.nav div:hover {
  cursor: pointer;
  color: #078080;
  text-decoration: underline;
}

.logo {
  z-index: 1;
  font-weight: 800;
  position: absolute;
  left: 0;
  top: 0;
  margin: 16px 0 0 25px;
  font-size: 1.5em;
}

.logo:hover{
  cursor: pointer;
  color: #078080;
}

.footer {
  font-weight: 800;
  position: absolute;
  bottom: 20px;
  color: #f8f5f2;
  font-size: 1em;
}

.footer-privacy {
  z-index: 1;
  font-weight: 800;
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: #f8f5f2;
  font-size: 1em;
  cursor: pointer;
}

.body {
  padding: 20px 30px 20px 30px;
}

/* basically deskptop adjustments */
@media (min-width: 480px) {
  .nav {
    margin: 40px 50px 0px 0px;
  }

  .logo {
    margin: 40px 0 0 50px;
  }

  .body {
    padding: 0px 200px 40px 200px;
  }

  .bottom-right {
    bottom: 100px;
  }

  .top-left {
    top: 100px;
  }
}

@media (max-width: 1400px) {
  .headline h1 {
    font-size: calc(200px + 0.390625vw);
    letter-spacing: -15px;
    /* letter-spacing: -15px; */
    /* font-size: 15em; */
  }

  .body {
    padding: 20px 30px 20px 30px;
  }

  .bottom-right {
    bottom: 100px;
  }
}


@media (max-width: 1200px) {
  .headline h1 {
    font-size: calc(150px + 0.390625vw);
    letter-spacing: -10px;
  }

  .body {
    padding: 20px 30px 20px 30px;
  }
}

@media (max-width: 600px) {
  .headline h1 {
    /* letter-spacing: -10px; */
    font-size: calc(100px + 0.390625vw);
  }

  .bottom-right {
    bottom: 100px;
  }

  .footer {
    position: unset;
    margin-bottom: 10px;
  }

  .footer-privacy {
    position: unset;
    font-size: 0.7em;
    /* background:#232323; */
    /* width: 100%; */
    /* bottom: 0; */
    /* right: unset; */
  }
}