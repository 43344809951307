.loading-html {
    position: absolute;
    width: 100%;
    height: 100%;
}

.pre-loader {
    z-index: 2;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.loader {
    z-index: 3;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-bg {
    position: absolute;
    display: block;
    top: 0;
    width: 100%;
    height: 100%;
    background: #f45d48;
    z-index: 1;
}

.loading-background {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    transform: translate(-50%, -50%);
}

.loading-title {
    margin: 0;
    flex: 8;
}

.loading-title p {
    font-size: 3em;
    font-weight: 800;
    text-align: left;
    color: #f45d48;
    letter-spacing: -1.5px;
    margin: 0;
    padding: 0;
    line-height: 0.75;
    font-family: 'GilroyExtraBold', 'Inter';
    overflow: hidden;
}

.loading-title .letter {
    display: inline-block;
    /* line-height: 1em; */
}

.loading-title p:nth-child(2) {
    color: #eff0f3;
    /* letter-spacing: 19px; */
}

.progress{
    flex: 2;
    padding: 0;
    margin: 0;
    font-size: 6em;
    color: #eff0f3;
    font-family: 'GilroyExtraBold', 'Inter';
}


@media (max-width: 900px) {
    .loading-background{
        width: 100%;
        flex-direction: column;
    }

    .progress{
        width: 100%;
        flex: 0;
    }

    .loading-title {
        flex: 0;
    }

}